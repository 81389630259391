import React, { useEffect } from 'react';
import axios from 'axios';

const DocumentDownloadPage = ({ data, location }) => {
  useEffect(() => {
    const pdfViewer = document.createElement('iframe');
    const fetchDocument = async () => {
      const url = new URL(window.location.href);
      const documentId = url.searchParams.get('documentId');
      const response = await axios.get(
        `${process.env.GATSBY_BACKEND_URL}/api/documents/${documentId}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/pdf',
          },
          responseType: 'blob',
          responseEncoding: 'binary',
        },
      );

      // Mock pdf in memory
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // createObjectURL is an alternative to data url's. The difference is that
      // Chrome and Edge have a 2mb limit size on url's, but allows for much bigger
      // files when using this approach.
      pdfViewer.src = URL.createObjectURL(pdfBlob);
      pdfViewer.onload = () => URL.revokeObjectURL(pdfViewer.src);

      // Styling related to demo
      pdfViewer.style.height = '100vh';
      pdfViewer.style.width = '100vw';
      pdfViewer.style.border = '0';
      pdfViewer.style.borderRadius = '6px';
      document.body.style.overflow = 'hidden';
      document.body.style.margin = '0';
      document.body.style.padding = '0';

      document.body.appendChild(pdfViewer);
    };
    fetchDocument();
    return () => {
      document.body.removeChild(pdfViewer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default DocumentDownloadPage;
